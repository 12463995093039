import React from 'react';
import { RichText } from 'prismic-reactjs';

import HistoryIndustryHero from '@/containers/HistoryIndustry/HistoryIndustryHero';
import HistoryIndustryPhilosophy from '@/containers/HistoryIndustry/HistoryIndustryPhilosophy';
import HistoryIndustryFullbleedBg from '@/containers/HistoryIndustry/HistoryIndustryFullbleedBg';
import HistoryIndustryStats from '@/containers/HistoryIndustry/HistoryIndustryStats';
// x
import HistoryIndustryTeam from '@/containers/HistoryIndustry/HistoryIndustryTeam';
import HistoryIndustryContactBox from '@/containers/HistoryIndustry/HistoryIndustryContactBox';

import {
  HistoryIndustryPageQuery,
  PrismicHistoryIndustryPagePhilosophyValuesGroupType,
  PrismicHistoryIndustryPageBody1Contactbox,
  PrismicHistoryIndustryPageBody2Companystats,
} from '../../../graphql-types';

interface Props {
  data: {
    prismicHistoryIndustryPage: HistoryIndustryPageQuery;
    prismicHistoryIndustryPageBody1Contactbox: PrismicHistoryIndustryPageBody1Contactbox;
    prismicHistoryIndustryPageBody2Companystats: PrismicHistoryIndustryPageBody2Companystats;
  };
  type: string;
}

const HistoryIndustryContainer = ({ data, type }: Props): JSX.Element => {
  const {
    hero_background,
    hero_title,
    hero_content,
    philosophy_title,
    philosophy_values,
    timeline_title,
    team_title,
    body,
  } = data?.prismicHistoryIndustryPage?.data || {};

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  // const { stats } = data?.prismicCompanyStats?.data || {};

  // const { primary } = data?.prismicHistoryPageBody1Contactbox || {};

  const { primary } = data?.prismicHistoryIndustryPageBody1Contactbox || {};

  const companyStats = data?.prismicHistoryIndustryPageBody2Companystats || {};

  const HeroProps = {
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
    title: <RichText render={hero_title?.raw} />,
    content: <RichText render={hero_content?.raw} />,
  };

  const PhilosophyProps = {
    title: <RichText render={philosophy_title?.raw} />,
    values: philosophy_values?.map(
      (v: PrismicHistoryIndustryPagePhilosophyValuesGroupType | null) => ({
        title: <RichText render={v?.title?.raw} />,
        content: <RichText render={v?.content?.raw} />,
      }),
    ),
  };

  const FullbleedBgProps = {
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  // const TimelineProps = {
  //   title: <RichText render={timeline_title?.raw} />,
  // };

  const TeamProps = {
    title: <RichText render={team_title?.raw} />,
    sliders: body?.map((slider) => {
      const { slider_title } = slider?.primary || {};

      return {
        sliderTitle: <RichText render={slider_title?.raw} />,
        members: slider?.items?.map((item) => ({
          photo: item?.photo,
          hidden_photo: item?.hidden_photo,
          name: item?.name,
          role: item?.role,
        })),
      };
    }),
  };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  return (
    <>
      <HistoryIndustryHero {...HeroProps} />
      <HistoryIndustryPhilosophy {...PhilosophyProps} />
      <HistoryIndustryFullbleedBg {...FullbleedBgProps} />
      {/* <HistoryIndustryStats {...StatsProps} /> */}
      {/* <HistoryIndustryTimeline {...TimelineProps} /> */}
      <HistoryIndustryTeam {...TeamProps} />
      {primary && <HistoryIndustryContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && (
        <HistoryIndustryStats {...StatsProps} />
      )}
    </>
  );
};

export default HistoryIndustryContainer;
