import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import AliceCarousel from 'react-alice-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { breakpoints } from '@/styles/theme';

import TeamMember from '@/components/molecules/TeamMember';
import Typography from '@/components/atoms/Typography';
import Container from '@/components/atoms/Container';
import Button from '@/components/atoms/Button';

import mq from '@/styles/mq';

import { MemberProps } from '@/components/molecules/TeamMember/TeamMember';

interface SliderProps {
  sliderTitle?: React.ReactNode;
  members?: MemberProps[];
}

interface Props {
  title?: React.ReactNode;
  sliders?: SliderProps[];
}

const HistoryIndustryTeamRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(15)};
`;
const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  text-align: center;

  ${mq('lg')} {
    text-align: left;
  }
`;
const TeamSliderRoot = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(9)};

  & .alice-carousel__stage-item {
    padding-right: 0;
    overflow: hidden;

    ${mq('md')} {
      padding-left: ${({ theme }) => theme.spacing(1)};
      padding-right: ${({ theme }) => theme.spacing(1)};
    }
  }
`;
const SliderTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  text-align: center;

  ${mq('lg')} {
    text-align: left;
  }
`;
const Arrow = styled(Button)`
  top: 140px;
  transform: translateY(-50%);

  ${mq('md')} {
    top: 180px;
  }

  ${mq('lg')} {
    /* display: none; */
    top: 230px;
  }
`;
const Prev = styled(Arrow)`
  position: absolute;
  left: 0;
  ${mq('md')} {
    left: -15px;
  }
`;
const Next = styled(Arrow)`
  position: absolute;
  right: 0;
  ${mq('md')} {
    right: -15px;
  }
`;
const MemberSlide = styled('div')``;

const responsive = {
  0: { items: 1 },
  [breakpoints.md]: { items: 3 },
  [breakpoints.lg]: { items: 3 },
};

const IndustryTeamSlider = ({
  sliderTitle,
  members,
}: SliderProps): JSX.Element => {
  const handleDragStart = (e: React.MouseEvent) => e.preventDefault();

  const Members = members?.map((member: MemberProps, index: number) => (
    <MemberSlide>
      <TeamMember
        key={index.toString()}
        onDragStart={handleDragStart}
        {...member}
      />
    </MemberSlide>
  ));

  return (
    <TeamSliderRoot>
      <SliderTitle variant="h4">{sliderTitle}</SliderTitle>
      <AliceCarousel
        autoPlay
        autoPlayInterval={2000}
        mouseTracking
        items={Members}
        infinite
        disableDotsControls
        responsive={responsive}
        renderPrevButton={(): JSX.Element => (
          <Prev variant="text">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Prev>
        )}
        renderNextButton={(): JSX.Element => (
          <Next variant="text">
            <FontAwesomeIcon icon={faArrowRight} />
          </Next>
        )}
      />
    </TeamSliderRoot>
  );
};

const HistoryIndustryTeam = ({ title, sliders }: Props): JSX.Element => {
  const Sliders = sliders?.map((slider: SliderProps, index: number) => (
    <IndustryTeamSlider key={index.toString()} {...slider} />
  ));

  return (
    <HistoryIndustryTeamRoot maxWidth="lg">
      <Title variant="h2">{title}</Title>
      <>{Sliders}</>
    </HistoryIndustryTeamRoot>
  );
};

export const query = graphql`
  fragment HistoryIndustryTeam on PrismicHistoryIndustryPageDataType {
    team_title {
      raw
    }
    body {
      ... on PrismicHistoryIndustryPageBodyTeamSlider {
        primary {
          slider_title {
            raw
          }
        }
        items {
          photo {
            url
            alt
          }
          hidden_photo {
            url
            alt
          }
          name
          role
        }
      }
    }
  }
`;

export default HistoryIndustryTeam;
