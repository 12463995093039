import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Background from '@/components/atoms/Background';
import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface Props {
  background?: ImageDataType;
}

const FullbleedRoot = styled(Background)`
  /* height: 700px; */
  width: 100vw;
  /* margin-top: ${({ theme }) => theme.spacing(10)}; */
  height: 500px;
`;

const ParallaxContainer = styled.div`
  width: 100%;
  height: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  ${mq('lg')} {
    height: 500px;
  }
`;

const HistoryIndustryFullbleedBg = ({ background }: Props): JSX.Element => {
  // <FullbleedRoot background={background} />
  return (
    <ParallaxContainer
      css={css`
        background-image: url(${background?.url});
      `}
    />
    // <ParallaxBanner
    //   layers={[
    //     {
    //       amount: 0.5,
    //       children: <FullbleedRoot background={background} />,
    //       expanded: false,
    //       props: {
    //         style: {
    //           top: -60,
    //         },
    //       },
    //     },
    //   ]}
    // />
  );
};

export const query = graphql`
  fragment HistoryIndustryFullbleedBg on PrismicHistoryIndustryPageDataType {
    fullbleed_background {
      url
      alt
    }
  }
`;

export default HistoryIndustryFullbleedBg;
