import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import Grid from '@/components/atoms/Grid';
import Typography from '@/components/atoms/Typography';
import mq from '@/styles/mq';

interface ValueProps {
  title?: React.ReactNode;
  content?: React.ReactNode;
}

interface Props {
  title?: React.ReactNode;
  values?: ValueProps[];
}

const HistoryIndustryPhilosophyRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${mq('lg')} {
    margin-top: ${({ theme }) => theme.spacing(15)};
  }
`;
const Title = styled(Typography)`
  text-align: left;
  ${mq('xs')} {
    text-align: center;
  }

  ${mq('lg')} {
    text-align: left;
    max-width: 250px;
  }
`;
const ValueRoot = styled(Grid)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  align-self: stretch;
  display: flex;
  flex-direction: column;
  ${mq('lg')} {
    margin-bottom: ${({ theme }) => theme.spacing(7)};
  }
`;
const ValuesGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  & ${ValueRoot}:not(:nth-of-type(2n)) {
    padding-right: ${({ theme }) => theme.spacing(7)};
  }

  ${mq('lg')} {
    margin-top: 0;
    max-width: 100%;
  }
`;
const ValueTitle = styled(Typography)`
  flex-basis: 40%;
`;
const ValueContent = styled(Typography)`
  flex-basis: 60%;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

const Value = ({ title, content }: ValueProps): JSX.Element => {
  return (
    <ValueRoot xxs={12} lg={6}>
      <ValueTitle variant="h4">{title}</ValueTitle>
      <ValueContent>{content}</ValueContent>
    </ValueRoot>
  );
};

const HistoryIndustryPhilosophy = ({ title, values }: Props): JSX.Element => {
  const Values = values?.map((value, i) => (
    <Value key={i.toString()} {...value} />
  ));

  return (
    <HistoryIndustryPhilosophyRoot maxWidth="lg">
      <Grid container>
        <Grid xxs={12} lg={4}>
          <Title variant="h2">{title}</Title>
        </Grid>
        <ValuesGrid container xxs={12} lg={8}>
          {Values}
        </ValuesGrid>
      </Grid>
    </HistoryIndustryPhilosophyRoot>
  );
};

export const query = graphql`
  fragment HistoryIndustryPhilosophy on PrismicHistoryIndustryPageDataType {
    philosophy_title {
      raw
    }
    philosophy_values {
      title {
        raw
      }
      content {
        raw
      }
    }
  }
`;

export default HistoryIndustryPhilosophy;
